import apiClient from './axiosConfig';

export const getLanguageDistribution = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/dashboard/get_language_distribution');
    return response.data.languages;
  } catch (error) {
    console.error('Ошибка при получении распределения языков:', error);
    throw error;
  }
};

export const sendOneMessage = async (userId, messageText) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/one_user', { userId, messageText });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const fetchDashboardApi = async () => {
  try {
    const response = await apiClient.post('/node/api/admin/dashboard');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const checkUserInfo = async (user_id) => {
  try {
    const response = await apiClient.post('/node/api/admin/get_user_info', (user_id));
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const updateUserStatus = async (userId, bannedStatus) => {
  try {
    const response = await apiClient.put('/node/api/admin/get_user_info/update_status', {
      user_id: userId,
      banned: bannedStatus
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса пользователя';
  }
};


export const closeLoansBulk = async (loanIds, currency, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/loans/close', {
      loanIds,
      currency,
      hash
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса займа';
  }
};



export const withdrawalSuccess = async (withdrawal_ids, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/success', {
      withdrawal_ids,
      hash
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
};

export const withdrawalRejected = async (withdrawal_id) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/rejected', {
      withdrawal_id
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
};

export const createLoanInTelegram = async (loanData) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/loan_in_group', loanData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const fetchLendingRequests = async (filters) => {
  const response = await apiClient.get(`/node/api/admin/p2p/lending_requests`, {
    params: filters,
  });
  return response.data;
};

export const fetchBorrowingRequests = async (filters) => {
  const response = await apiClient.get(`/node/api/admin/p2p/borrowing_requests`, {
    params: filters,
  });
  return response.data;
};

export const createWithdrawalInTelegram = async (withdrawalData) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/withdrawal_in_group', withdrawalData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const fetchNotificationsApi = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/notifications');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const soldLoan = async (loanId, amount, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/loans/sale', { loanId, amount, hash });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при продаже займа';
  }
}

export const getPartnersList = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/partners');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка';
  }
};

export const partnersWithdrawals = async (hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/partners', { hash });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
}

export const getOneHourActionList = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/actions/oneHour');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка';
  }
};


export const closePayouts = async (currency) => {
  try {
    const response = await apiClient.put('/node/api/admin/actions/closePayouts', { currency });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при закрытии займов';
  }
}
