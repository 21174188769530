import React, { lazy, useEffect } from 'react'
import './App.css';
import './api/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import useAuth from './app/auth';
import { fetchNotifications } from './actions/slices/notifications';
import { Spin } from 'antd';
// import { connect, disconnect } from './actions/slices/websocketSlice';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))

function App() {
  const dispatch = useDispatch();
  // const { isConnected, messages } = useSelector(state => state.websocket);

//   useEffect(() => {
//     dispatch(connect());
// console.log(isConnected)
// console.log(messages)
//     return () => {
//       dispatch(disconnect());
//     };
//   }, [dispatch]);
useEffect(() => {
  dispatch(fetchNotifications());
}, [dispatch]);
  useEffect(() => {
    themeChange(false)
  }, [])

  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <Spin spinning={loading} fullscreen />;
  }
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />

          <Route
          path="/app/*"
          element={
            isAuthenticated ? (
              <Layout />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

          <Route path="*" element={<Navigate to={isAuthenticated ? "/app/welcome" : "/login"} replace />} />

        </Routes>
      </Router>

    </>
  )
}

export default App
